.create-orga-btn {
    display: flex;
    justify-content: center;
    padding: 10px 10px 0px 10px;
}

.menu-orga {
    height: 100%;
}

.menu-orga :global(.wz-menu-alternative) {
    background-color: rgb(var(--lighter-color));
}
