.select-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-fill-available;
    position: absolute;
    overflow: hidden;
    margin-top: -55px;
}

.switch-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-fill-available;
    height: 100px;
    font-size: 12px;
    overflow: hidden;
}
