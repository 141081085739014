.pagination-dropdown {
    display: flex;
    justify-content: space-between;
}

.pagination-dropdown :global(.wz-dropdown-input) {
    width: 225px;
    max-width: 225px;
}

.pagination-dropdown :global(.wz-input-icon__right) {
    top: 0;
}

.pagination-dropdown :global(.wz-input-text) {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.formatters-table td {
    white-space: nowrap;
}

.formatters-table td.url-wrap {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
}

.formatters-table-medium {
    width: 100px;
}

.formatters-checkbox[datatype='disabled'] :global(.wz-ui-input) {
    opacity: 1;
    position: relative;
    cursor: not-allowed;
}

.formatters-checkbox[datatype='disabled'] :global(.wz-input-checkbox) {
    opacity: 0.2;
    pointer-events: none;
}

.formatters-checkbox[datatype='disabled'] :global(.wz-ui-input)::after {
    content: var(--tooltip-message);
    min-width: 305px;
    font-size: 12px;
    position: absolute;
    padding: 14px 10px;
    left: 1.5rem;
    top: 2rem;
    background-color: #1e2332;
    color: white;
    border-radius: 5px;
    font-size: 12px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    outline: none;
}

.formatters-checkbox :global(.wz-ui-input):hover::after {
    opacity: 1;
    visibility: visible;
}

@media (max-width: 650px) {
    .formatters-checkbox[datatype='disabled'] :global(.wz-ui-input)::after {
        min-width: 100px;
        font-size: 10px;
        left: 2remfcong;
        top: 2.5rem;
    }
}
