$white: #fff;
$sauronColor: #2F3237;
$grey: #F2F2F2;
$greyLight: #F4F4F4;
$darkerColor: #000000;
$lighterColor: #F8F9FC;

$iconTopBorderColor: $white;
$iconTopBorderHoverColor: $white;

$primaryColor: $sauronColor;
$mediumColor: #1D1E20;
$alphaColor: #F1F3F9;
$colorless: transparent;