/* User informations */
.user-identity {
    display: flex;
    align-items: center;
}

.user-fullname {
    margin-left: 15px;
    font-weight: 500;
    font-size: 16px;
}

.user-details {
    display: flex;
    margin-top: 10px;
}

.user-edit {
    position: absolute;
    right: 0px;
    cursor: pointer;
    white-space: nowrap;
    font-weight: 500;
}

.user-informations,
.user-subdetails {
    position: relative;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.user-subdetails {
    justify-content: flex-start;
}
.user-detail {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.user-detail .user-detail-label {
    font-size: 12px;
    color: #878c9b;
}

.user-detail .user-detail-content {
    font-weight: 500;
}

/* User roles */
.user-roles-header {
    display: flex;
    justify-content: space-between;
}

.user-roles-header :global(.wz-framebutton) {
    position: absolute;
    right: 10px;
}

.user-roles-table td {
    white-space: nowrap;
}

.user-roles-table-small {
    width: 30px;
}

.user-roles-table-medium {
    width: 80px;
}

.user-roles-table-large {
    width: 120px;
}

.application-block {
    display: flex;
    flex-direction: column;
}

.application-cc-icon-color {
    color: rgb(var(--primary-color));
}

.application-message :global(.wz-message) {
    filter: grayscale(1);
}

.application-security-message :global(.wz-message.wz-message__default) {
    border: 1px solid rgb(var(--primary-color));
}

.application-security-message :global(.wz-message-icon-container) {
    display: none;
}

.application-security-message :global(.wz-message-message) {
    margin-left: 20px;
}

.application-client-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.application-data {
    overflow: hidden;
    overflow-y: auto;
    background-color: rgb(var(--lighter-color));
    border: solid 1px #e2dbdb;
    border-radius: 6px;
    padding: 5px;
    color: #878c9b;
}
