.pagination-dropdown {
    display: flex;
    justify-content: space-between;
}

.pagination-dropdown :global(.wz-dropdown-input) {
    width: 225px;
    max-width: 225px;
}

.pagination-dropdown :global(.wz-input-icon__right) {
    top: 0;
}

.pagination-dropdown :global(.wz-input-text) {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.users-table td {
    white-space: nowrap;
}

.users-table-small {
    width: 80px;
}

.users-table-medium {
    width: 100px;
}

.user-input {
    margin-bottom: 20px;
}

.user-role-accordion :global(.wz-accordion__text) {
    width: -webkit-fill-available !important;
}

.user-role-toggle,
.user-role-title {
    display: flex;
    align-items: center;
}

.user-role-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.user-role-toggle {
    cursor: pointer;
    margin-top: 15px;
}

.user-role-toggle :global(.wz-font-mini) {
    font-size: 0.7em !important;
    margin-right: 0.7em !important;
}

.user-role-toggle b {
    font-weight: 500;
}
