.ticket-card {
    border-radius: 5px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.33);
    margin-bottom: 15px;
}

.ticket-card-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 15px 20px;
    background-color: rgba(0, 0, 0, 0.04);
}

.ticket-card-content {
    padding: 15px 20px;
    background-color: #fff;
    border-bottom: 1px solid #ced0da;
}

.ticket-card-content-ticket {
    border-bottom: none;
    background-image: linear-gradient(to right, #ced0da 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 10px 1px;
    background-repeat: repeat-x;
    position: relative;
}

.ticket-card-content-ticket:before,
.ticket-card-content-ticket:after {
    content: ' ';
    width: 20px;
    height: 20px;
    border-radius: 200px;
    background-color: #f5f5f5;
    position: absolute;
    bottom: -10px;
}

.ticket-card-content-ticket:before {
    left: -10px;
    box-shadow: inset -2px 0px 1px 0px rgb(0 0 0 / 15%);
}
.ticket-card-content-ticket:after {
    right: -10px;
    box-shadow: inset 2px 0px 1px 0px rgb(0 0 0 / 15%);
}

.ticket-card-footer {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 15px 20px;
    background-color: #fff;
}
