.paginator-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.paginator-container :global(.wz-paginator .active) {
    background-color: rgb(var(--primary-color));
    box-shadow: 0 2px 3px 0 rgba(var(--primary-color), 0.3);
}
