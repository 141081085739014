.date-time-input {
    flex-direction: column;
    margin-bottom: 15px;
}

.date-container {
    display: flex;
}

@media screen and (max-width: 600px) {
    .date-container {
        flex-direction: column;
    }
}
