.formatter-detail-label {
    font-weight: 500;
    margin: 20px 0px 20px 0px;
}

.formatter-input {
    margin-bottom: 20px;
}

.formatter-input-label {
    margin-bottom: 5px;
}

.formatter-input-label :global(.wz-infotext__subtitle) {
    padding-top: 0 !important;
}

.formatter-input :global(.jsoneditor) {
    border-radius: 6px;
    overflow: hidden;
    border: thin solid rgba(47, 50, 55, 0.25);
}

.formatter-input[datatype='disabled-json-editor'] :global(.jsoneditor) {
    filter: grayscale(1);
    opacity: 0.5;
    cursor: not-allowed !important;
}

.formatter-input[datatype='disabled-json-editor'] :global(.jsoneditor) * {
    pointer-events: none;
}

.formatter-section-title {
    font-weight: 500;
    margin-bottom: 10px;
}
