.infotext-tooltip :global(.wz-tooltip-without-margin) {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.infotext-tooltip :global(.wz-popin__container) {
    display: block
}

.infotext-tooltip .infotext-tooltip__span-tooltip, .infotext-tooltip .infotext-tooltip__span-tooltip span {
    white-space: break-spaces;
    font-weight: normal;
}
