.role-action-input {
    margin-bottom: 20px;
}

.role-action-textarea {
    resize: vertical;
}

.role-action-toggle-container {
    width: 200px;
}
