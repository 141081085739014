.label-detail {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    overflow: hidden;
    max-width: 65%;
}

.label-detail-label {
    font-size: 12px;
    color: #878c9b;
    margin-bottom: 5px;
}

.label-detail-content {
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex-grow: 1;
    min-width: 0;
}
