.jsoneditor-field :global(.jsoneditor) {
    border-color: rgb(var(--primary-color));
}

.jsoneditor-field-container {
    margin-bottom: 15px;
}

.jsoneditor-field :global(.jsoneditor-outer) {
    height: 400px;
}

.jsoneditor-field :global(button:hover) {
    background-color: black !important;
}

.jsoneditor-field :global(.jsoneditor-mode-code, .jsoneditor-mode-tree) {
    border-color: rgb(var(--primary-color));
}

.jsoneditor-field :global(button:hover) {
    background-color: black !important;
}

.jsoneditor-field :global(button.jsoneditor-type-modes.jsoneditor-selected) {
    background-color: grey !important;
}

.jsoneditor-field :global(.jsoneditor-menu) {
    background-color: rgb(var(--primary-color));
}

.jsoneditor-field :global(.jsoneditor-text) {
    color: white;
}

.jsoneditor-field :global(.jsoneditor .jsoneditor-text-errors) {
    border-color: red;
}

.jsoneditor-field :global(.jsoneditor .jsoneditor-text-errors tr.parse-error) {
    background-color: #fbebeb;
}

.jsoneditor-field :global(.ace-jsoneditor) {
    max-height: 548px; /* 30 lines */
    min-height: 182px; /* 10 lines */
}

.jsoneditor-field :global(.jsoneditor-repair) {
    pointer-events: none;
    width: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
}

.jsoneditor-field :global(.ace_content *) {
    font-family: monospace;
}
