.quick-filters-container {
    display: flex;
    align-items: baseline;
}

.quick-filters {
    display: flex;
    align-items: baseline;
}
.quick-filters,
.quick-filters-label {
    margin-right: 10px;
}

.quick-filters :global(.wz-field-block) {
    margin-bottom: 0 !important;
}

@media screen and (max-width: 800px) {
    .quick-filters-container {
        flex-direction: column;
    }
}
