.loader-container {
    position: relative;
    display: inline-block;
}

.loader-count-loader {
    position: relative !important;
    top: auto !important;
    left: 2px;
    margin-right: 4px;
    width: 20px !important;
    z-index: auto !important;
}

.loader-count-loader::before,
.loader-count-loader::after {
    width: 15px !important;
    height: 15px !important;
}
