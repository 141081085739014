.pagination-dropdown {
    display: flex;
    justify-content: space-between;
}

.pagination-dropdown-block {
    width: auto;
}

.pagination-dropdown :global(.wz-dropdown-input) {
    width: 225px;
    max-width: 225px;
}

.pagination-dropdown :global(.wz-input-icon__right) {
    top: 0;
}

.pagination-dropdown :global(.wz-input-text) {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

@media screen and (max-width: 650px) {
    .pagination-dropdown {
        flex-direction: column;
        margin-bottom: 5px;
    }

    .pagination-dropdown :global(.wz-dropdown-input),
    .pagination-dropdown :global(.wz-framebutton),
    .pagination-dropdown :global(.wz-tooltip),
    .pagination-dropdown :global(.wz-popin__container) {
        width: 100%;
        max-width: none;
    }

    .pagination-dropdown :global(.wz-input-text) {
        text-align: left;
    }
}
