/* BoItem */
.bo-item {
    cursor: pointer;
    padding: 13px;
    border-radius: 5px;
}

.bo-item,
.bo-item:hover {
    color: unset;
}

.bo-item:hover {
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.bo-item-logo {
    height: 25px;
}

.bo-item-text {
    margin-top: 5px;
    font-size: 12px;
    text-align: center;
}

/* Header */
.header-title {
    font-size: 24px;
    padding-top: 20px;
}

.header-user-name {
    color: #0032fa;
}

.header-subtitle {
    margin: 20px 0;
}

/* OrganizationList */
.organization-card {
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.organization-card:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}

.organization-header {
    display: flex;
    justify-content: space-between;
}

.organization-id-container {
    display: flex;
    align-items: center;
    color: #878c9b;
}

.organization-id {
    margin: 0 5px;
}

.organization-name {
    font-weight: 500;
    margin: 10px 0;
}

.organization-dropdown {
    margin: 0 15px 10px 0px;
}

.organization-dropdown :global(.wz-commondropdown) {
    width: 39px;
    height: 39px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.organization-dropdown :global(.wz-commondropdown__selectors) {
    margin-top: 16px;
    margin-left: 16px;
}

.organization-bo-items {
    display: grid;
    grid-template-columns: repeat(auto-fit, 175px);
    grid-gap: 15px;
}

@media screen and (max-width: 1100px) {
    .bo-item-logo {
        height: 20px;
    }
    .bo-item-text {
        display: none;
    }
}

@media screen and (max-width: 650px) {
    .organization-actions {
        text-align: center;
    }
}

@media screen and (max-width: 438px) {
    .organization-bo-items {
        grid-template-columns: 1fr;
    }
}
