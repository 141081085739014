.pagination-dropdown {
    display: flex;
    justify-content: space-between;
}

.pagination-dropdown :global(.wz-dropdown-input) {
    width: 225px;
    max-width: 225px;
}

.pagination-dropdown :global(.wz-input-icon__right) {
    top: 0;
}

.pagination-dropdown :global(.wz-input-text) {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.webhooks-table td {
    white-space: nowrap;
}
.webhooks-table td.url-wrap span {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 300px;
}
.webhooks-table-medium {
    width: 100px;
}

.webhook-calls-block {
    display: flex;
    flex-direction: column;
}

.webhook-calls-success {
    color: #39c433;
}

.webhook-calls-fail {
    color: #f0480a;
}

.external-link {
    cursor: pointer;
}

.external-icon {
    text-align: center;
    margin-left: 5px;

    > i {
        font-size: 10px !important;
        margin: 0;
        flex: 0 0 auto;
    }
}
